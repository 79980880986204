/*
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1440px,
*/
div,
input{
  box-sizing: border-box;
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: 'DM Sans', sans-serif;
  font-size: 21px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}