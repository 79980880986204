.page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.form-view {
    background-image: url('../assets/sign-up-1-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  &.lightwhite {
    background-color: #F9F9F9;
  }

  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;
  }
}

.sign-up-form-wrapper {
  display: flex;
  justify-content: center;
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;
  min-height: 606px;

  @media (max-width: 992px) {
    min-height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 30px 0;
  }

  .info-wrap {
    width: 40%;
    background-image: url('../assets/sign-up-1-left-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 50px 50px 65px;
    border-radius: 20px 0 0 20px;

    @media (max-width: 992px) {
      width: 45%;
      padding: 40px 35px
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 40px 15px;
      border-radius: 20px 20px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 768px) {
      padding: 30px 15px;
    }

    &.full {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 20px;

      .login-link {
        color: white;
        margin-top: 15px;
      }
    }

    .logo {
      font-style: normal;
      font-weight: 400;
      font-size: 44px;
      line-height: 100%;
      letter-spacing: 0.105em;
      text-transform: uppercase;
      background: radial-gradient(50% 50% at 50% 50%, rgba(7, 208, 236, 0.2) 0%, rgba(255, 122, 0, 0) 100%), linear-gradient(90deg, rgba(47, 130, 255, 0.8) -8.06%, rgba(251, 44, 255, 0.8) 109.68%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      mix-blend-mode: lighten;
      margin-bottom: 74px;

      @media (max-width: 768px) {
        margin-bottom: 40px;
      }

      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }

    .left-heading {
      font-style: normal;
      font-weight: 700;
      font-size: 35px;
      line-height: 39px;
      letter-spacing: -0.04em;
      color: #FFFFFF;
      margin: 0 0 30px 0;

      @media (max-width: 992px) {
        font-size: 30px;
        line-height: 35px;
      }

      @media (max-width: 768px) {
        text-align: center;
      }

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .pros-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 27px;
        color: #FFFFFF;

        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 22px;
        }

        &:not(:last-child) {
          margin-bottom: 3px;

          @media (max-width: 768px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .sign-up-button-wrap {
    width: 60%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    border-radius: 0 20px 20px 0;

    @media (max-width: 992px) {
      width: 55%;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 40px 15px;
      border-radius: 0 0 20px 20px;
    }

    h1 {
      width: 100%;
      font-style: normal;
      font-weight: 700;
      font-size: 43px;
      line-height: 47px;
      text-align: center;
      letter-spacing: -0.04em;
      color: #2B2B2B;
      margin: 0 0 28px 0;

      @media (max-width: 768px) {
        font-size: 36px;
        line-height: 42px;
      }
    }

    .buttons-wrap {
      max-width: 375px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      a.button-link {
        width: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: #3351F5;
        transition: 0.3s ease-in all;
        color: #FFFFFF;
        font-size: 21px;
        line-height: 27px;

        @media (max-width: 768px) {
          height: 40px;
          font-size: 18px;
          line-height: 23px;
        }

        &:hover {
          background-color: #3351F5D9;
        }
        
        &.google-link {
          position: relative;
          background-color: #5086EC;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          color: #FFFFFF;

          &:hover {
            background-color: #3351F5;
          }

          img {
            position: absolute;
            left: 7px;
            top: 7px;
            width: 37px;
            height: 37px;

            @media (max-width: 768px) {
              left: 5px;
              top: 5px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }

      .divider {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #C2C2C2;
        text-transform: uppercase;
        margin: 16px 0;

        @media (max-width: 768px) {
          margin: 10px 0;
        }
      }

      .have-acc-text {
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 27px;
        color: #2B2B2B;
        display: flex;
        align-items: center;

        @media (max-width: 992px) {
          flex-direction: column;
        }

        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 22px;
        }

        a {
          color: #2F7DF6;
          transition: 0.2s ease-in all;
          margin: 0 0 0 16px;

          @media (max-width: 992px) {
            margin: 10px 0 0 0;
          }

          &:hover {
            color: #3351F5;
          }
        }
      }
    }
  }
}

.sign-up-form {
  max-width: 385px;
  margin: 0 auto;

  input {
    width: 100%;
    height: 40px;
    padding: 9px 14px;
    border: 1px solid #CFD4D9;
    border-radius: 4px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #2B2B2B;
    margin-bottom: 12px;

    &:not(:last-of-type ){
      margin-bottom: 25px;
    }
  }

  .password-description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #898989;
    margin-bottom: 18px;
  }

  .form-post-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
    color: #2B2B2B;

    a {
      color: #2F7DF6;
      transition: 0.3s ease-in all;

      &:hover {
        color: #3351F5;
      }
    }
  }

  .error {
    color: red;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3351F5;
    border-radius: 4px;
    height: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
    border: 0;
    outline: none;
    width: 100%;
    transition: 0.3s ease-in all;

    &:hover {
      cursor: pointer;
      background-color: #3351F5D9;
    }
  }

  .after-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #98999A;
    text-align: center;
  }
}

.sign-up-roles-wrapper {
  padding: 70px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1080px;
  width: 100%;
  min-height: 787px;
  background-color: rgba(126, 55, 216, 0.06);
  border-radius: 20px 34px 34px 34px;

  @media (max-width: 992px) {
    min-height: auto;
  }

  @media (max-width: 768px) {
    margin: 30px 15px;
  }

  .head-wrap {
    margin: 0 auto 54px;
  }

  .role-items {
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 576px) {
      flex-direction: column;
      width: 100%;
    }

    .role-item {
      width: calc(50% - 15px);
      padding: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FAF9FE;
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 25px;
      text-align: center;
      color: #000000;
      margin-bottom: 18px;
      transition: 0.2s ease-in all;

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 23px;
      }

      @media (max-width: 576px) {
        width: 100%;
      }

      &:hover,
      &.selected{
        cursor: pointer;
        background-color: #F7F3FD;
        color: #7E37D8;
      }
    }
  }
}

.head-wrap {
  max-width: 420px;
  margin: 0 auto 54px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 39px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #2B2B2B;
    margin: 0 0 28px 0;

    @media (max-width: 992px) {
      font-size: 30px;
      line-height: 35px;
    }

    @media (max-width: 768px) {
      text-align: center;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #98999A;
    margin: 0;
  }
}

.sign-up-two-parts-wrapper {
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;

  .top-level {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(98, 32, 182, 0.07);
    border-radius: 20px;
    height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;

    @media (max-width: 768px) {
      padding: 0 20px;
      height: 360px;
    }

    @media (max-width: 576px) {
      padding: 40px 20px;
      height: auto;
    }

    .head-wrap {
      margin: 0 0 30px 0;

      h1 {
        margin: 0;
      }
    }

    .employee-items {
      max-width: 650px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 576px) {
        flex-direction: column;
        width: 100%;
      }

      .employee-item {
        width: calc(50% - 15px);
        padding: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FAF9FE;
        border-radius: 4px;
        font-size: 19px;
        line-height: 25px;
        text-align: center;
        color: #000000;
        margin-bottom: 18px;
        transition: 0.2s ease-in all;

        @media (max-width: 768px) {
          font-size: 17px;
          line-height: 23px;
        }

        @media (max-width: 576px) {
          width: 100%;
        }

        &:hover,
        &.selected{
          cursor: pointer;
          background-color: #ECDFFF;
        }
      }
    }

    .apps-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      
      .app-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        text-transform: capitalize;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 20px;
        }

        img {
          margin-bottom: 10px;
          height: 110px;
          object-fit: cover;
          transition: 0.3s ease-in all;
          border: 3px solid transparent;

          @media (max-width: 576px) {
            height: 80px;
          }
        }

        &:not(:last-child) {
          margin-right: 15px;
        }

        &:hover {
          cursor: pointer;

          img {
            border: 3px solid #98999A;
          }
        }

        &.selected img{
          border: 3px solid #98999A;
        }
      }
    }

    .error {
      color: red;
    }

    .more-platform {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #7D9FF5;
      transition: 0.3s ease-in all;
      margin-bottom: 30px;

      &:hover {
        color: #3351F5;
      }
    }

    .submit-link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background-color: #3351F5;
      border-radius: 4px;
      max-width: 640px;
      width: 100%;
      margin: 0 auto;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #FFFFFF;
      transition: 0.3s ease-in all;

      &:hover {
        background-color: #3351F5D9;
      }
    }
  }

  .bottom-level {
    background-color: rgba(255,255,255,.54);
    box-shadow: 0px 0px 35px rgba(98, 32, 182, 0.07);
    border-radius: 20px;
    height: 240px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    @media (max-width: 576px) {
      padding: 40px 20px;
      height: auto;
    }

    .quote-wrap {
      position: relative;
      max-width: 780px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .quote {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.5px;
        color: #000000;
        margin-bottom: 40px;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 21px;
        }

        .open-quote {
          font-family: 'Nunito', sans-serif;
          position: absolute;
          top: -70px;
          left: -80px;
          font-weight: 600;
          font-size: 158px;
          line-height: 216px;
          letter-spacing: 0.5px;
          text-transform: lowercase;
          color: rgba(0, 0, 0, 0.04);

          @media (max-width: 992px) {
            display: none;
          }
        }

        .close-quote {
          font-family: 'Nunito', sans-serif;
          position: absolute;
          bottom: -75px;
          right: -20px;
          font-style: normal;
          font-weight: 600;
          font-size: 158px;
          line-height: 216px;
          letter-spacing: 0.5px;
          text-transform: lowercase;
          color: rgba(0, 0, 0, 0.04);

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      .author-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 40px;

        img {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          margin-right: 16px;
        }

        .name {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.5px;
          color: #2B2B2B;
        }

        .position {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.5px;
          color: #898989;


        }
      }
    }
  }
}

